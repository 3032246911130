import "../styles/Login.scss";
import { Authenticator, Button, Flex, Heading, Loader, useAuthenticator, useTheme, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Suspense, useEffect, useState } from "react";
import { getAWSToken } from "../services/getAWSToken";
import LoginSlider from "../components/shared/LoginSlider";
import ACLoadingPanel from "../components/shared/ACLoadingPanel";
import { signInWithRedirect } from "@aws-amplify/auth";
import { FaApple } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { CiMail } from "react-icons/ci";
import { IoArrowBackOutline } from "react-icons/io5";
import { isNotNullAndUndefined, IsNotNullOrWhiteSpace } from "../RAFComponents/helpers/utils";

interface IProps {
    Component?: React.ComponentType, pageProps?: any;
}
export default function LoginPageNew({ Component, pageProps }: IProps) {

    const clientLogo = `${window.location.origin}/Content/img/workesio logo.png`;
    const [signInClicked, setSignInClicked] = useState(false);

    const { user } = useAuthenticator((context) => [context.user]);
    console.log('LoginPageNew user:', user);


    useEffect(() => {
        const fetchToken = async () => {

            console.log('LoginPage user:', user);
            if (user && user.userId) {
                console.log('LoginPage user.userId:', user.userId);
                const accessToken = await getAWSToken();
                console.log('LoginPage accessToken:', accessToken);
            }
        };
        const redirectToHome = () => {
            if (user !== null && user !== undefined && user.userId !== null && user.userId !== undefined) {
                window.location.href = "/";
            }
        };
        redirectToHome();
        //fetchToken();
    }, [user]);



    const signInMSClick = async (event) => {
        event.preventDefault();
        signInWithRedirect({
            provider: { custom: 'MicrosoftEntraID' },
        }).catch((e) => {
            alert('signInMSClick error' + JSON.stringify(e));
            console.error('signInMSClick error', e);
        });
    };

    const signInGoogleClick = async (event) => {
        event.preventDefault();
        signInWithRedirect({
            provider: 'Google',
        }).catch((e) => {
            alert('signInGoogleClick error' + JSON.stringify(e));
            console.error('signInGoogleClick error', e);
        });
    };

    const signInAppleClick = async (event) => {
        event.preventDefault();
        signInWithRedirect({
            provider: 'Apple',
        }).catch((e) => {
            alert('signInAppleClick error' + JSON.stringify(e));
            console.error('signInAppleClick error', e);
        });
    };

    const signInEmailClick = async (event) => {
        event.preventDefault();
        setSignInClicked(true);
    };

    const IconMS = () => {
        return (
            <svg width="14" height="14" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
                <rect x="5" y="5" width="50" height="50" fill="#F25022" />
                <rect x="65" y="5" width="50" height="50" fill="#7FBA00" />
                <rect x="5" y="65" width="50" height="50" fill="#00A4EF" />
                <rect x="65" y="65" width="50" height="50" fill="#FFB900" />
            </svg>

        );
    };
    const IconGoogle = () => {
        return (
            <FcGoogle />
        );
    };
    const IconApple = () => {
        return (
            <FaApple />
        );
    };
    const IconEmail = () => {
        return (
            <CiMail />
        );
    };

    return (
        signInClicked ? (
            <Authenticator
                hideSignUp={true}
                // initialState="signUp"
                socialProviders={[]}
                components={{
                    SignIn: {
                        Header() {
                            const { tokens } = useTheme();

                            return (
                                <Flex
                                    gap={'0.5rem'}
                                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                                    paddingBottom={0}
                                    alignItems={"center"}
                                >
                                    <Button
                                        size="small"
                                        variation="link"
                                        onClick={() => setSignInClicked(false)}
                                        padding={'0.5rem'}
                                        borderRadius={'50%'}
                                        colorTheme="overlay"
                                    >
                                        <IoArrowBackOutline size={20} />
                                    </Button>
                                    <Heading
                                        level={5}
                                    >
                                        Sign in to your account
                                    </Heading>
                                </Flex>
                            );
                        },
                        // Footer() {
                        //     const { toForgotPassword } = useAuthenticator();

                        //     return (
                        //         <View textAlign="center">
                        //             <Button
                        //                 fontWeight="normal"
                        //                 onClick={toForgotPassword}
                        //                 size="small"
                        //                 variation="link"
                        //             >
                        //                 Reset Password
                        //             </Button>
                        //         </View>
                        //     );
                        // },
                    },

                    // SignIn: (props) => {
                    //     const { toFederatedSignIn, ...rest } = props;
                    //     return (
                    //         <Authenticator.SignIn
                    //             {...props}
                    //             hideSignUp={true}
                    //             // Header={SignInHeader}
                    //         />
                    //     );
                    // },
                }}

            // formFields={{
            //     signIn: {
            //         username: {
            //             defaultValue: 'Enter your email12',
            //             isReadOnly: true,
            //         }
            //     },
            // }}
            >
                {({ signOut, user }) => (
                    <main>
                        <h1>Hello {user?.signInDetails.loginId}</h1>
                        <button onClick={signOut}>Sign out</button>
                        {Component && <Component {...pageProps} />}
                    </main>
                )}
            </Authenticator>) :
            <div className="h-100">
                <div className="h-100 content panel p-0">
                    <div className="h-100 d-flex justify-content-lg-end justify-content-center">
                        <div className="right-section d-none d-lg-block min-vh-100">
                            <div className="h-100 position-relative">
                                <Suspense fallback={<ACLoadingPanel loadingText="Loading..." />}>
                                    <LoginSlider />
                                </Suspense>
                            </div>
                        </div>
                        <div className="left-section bg-white">
                            <div className="loginContainer overflow-auto d-flex align-items-center flex-column">
                                <div className="w-100 formContainer d-flex flex-column align-items-center justify-content-center">
                                    <div className="text-center">
                                        <img src={clientLogo} alt="Product Logo" className="clientlogoImage mb-3" />
                                        <div className="d-flex justify-content-center align-items-center pb-2">
                                            <h5>Experience Seamless Intelligent Outcomes</h5>
                                        </div>
                                        {isNotNullAndUndefined(user) && IsNotNullOrWhiteSpace(user.userId) ?
                                            <Flex gap={"0.5rem"} direction={"column"}
                                                alignItems={"center"}
                                            >
                                                <Loader
                                                    size="large"
                                                />
                                                <Heading
                                                    level={5}
                                                    color={"neutral.100"}
                                                >
                                                    One moment while we sign you in...
                                                </Heading>
                                            </Flex>
                                            :
                                            <Flex direction="column" gap="1rem">
                                                <Button gap="0.5rem" isFullWidth={true} onClick={signInMSClick}>
                                                    <IconMS /> Sign In with Microsoft
                                                </Button>
                                                <Button gap="0.5rem" isFullWidth={true} onClick={signInGoogleClick}>
                                                    <IconGoogle /> Sign In with Google
                                                </Button>
                                                <Button gap="0.5rem" isFullWidth={true} onClick={signInAppleClick}>
                                                    <IconApple /> Sign In with Apple
                                                </Button>
                                                <Button gap="0.5rem" isFullWidth={true} onClick={signInEmailClick}>
                                                    <IconEmail /> Sign In with Email
                                                </Button>
                                            </Flex>
                                        }
                                        <div id="redirectDiv" className="hidden"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};


