import { useIsAuthenticated, useMsal } from '@azure/msal-react';
//import Slider from 'infinite-react-carousel';
import { Suspense, useEffect } from 'react';
//import * as repositoryActions from '../RAFComponents/store/actions/repositoryActions';
import "./../styles/Login.scss";
import ACLoadingPanel from '../components/shared/ACLoadingPanel';
import LoginSlider from '../components/shared/LoginSlider';
import { Flex, Heading, Loader } from '@aws-amplify/ui-react';


const SignoutPage = ({ }) => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const clientLogo = `${window.location.origin}/Content/img/workesio logo.png`;


    //call logoutClick on component load
    useEffect(() => {
        console.log("SignoutPage useEffect");
        logoutClick();
    }, []);





    const logoutClick = async () => {
        const localStorageMicrosoftUser = localStorage.getItem("microsoftUser");
        if (localStorageMicrosoftUser) {
            localStorage.removeItem("microsoftUser");
        }
        const logoutUrl = localStorageMicrosoftUser
            ? `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`
            : window.location.origin;

        window.location.href = logoutUrl;
    };

    return (
        <>


            <div className="h-100">
                <div className="h-100 content panel p-0">
                    <div className="h-100 d-flex justify-content-lg-end justify-content-center">
                        <div className="right-section d-none d-lg-block min-vh-100">
                            <div className="h-100 position-relative">
                                <Suspense fallback={<ACLoadingPanel loadingText="Loading..." />}>
                                    <LoginSlider />
                                </Suspense>
                            </div>
                        </div>
                        <div className="left-section bg-white">
                            <div className="loginContainer overflow-auto d-flex align-items-center flex-column">
                                <div className="w-100 formContainer d-flex flex-column align-items-center justify-content-center">
                                    <div className="text-center">
                                        <img src={clientLogo} alt="Product Logo" className="clientlogoImage mb-3" />
                                        <div className="d-flex justify-content-center align-items-center pb-2">
                                            <h5>Experience Seamless Intelligent Outcomes</h5>
                                        </div>
                                        <Flex gap={"0.5rem"} direction={"column"}
                                            alignItems={"center"}
                                        >
                                            <Loader
                                                size="large"
                                            />
                                            <Heading
                                                level={5}
                                                color={"neutral.100"}
                                            >
                                                One moment while we sign out completely...
                                            </Heading>
                                        </Flex>
                                        <div id="redirectDiv" className="hidden"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};


export default SignoutPage;