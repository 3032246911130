import { fetchAuthSession } from "aws-amplify/auth";

export const getAWSToken = async () => {
    return new Promise<string | null>(async (resolve, reject) => {

        const { accessToken } = (await fetchAuthSession()).tokens ?? {};
        if (accessToken?.toString()) {
            resolve(accessToken.toString());
        }
        else {
            resolve(null);
        }
    });
};