import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
//import Slider from 'infinite-react-carousel';
import { Suspense, useEffect } from 'react';
//import * as repositoryActions from '../RAFComponents/store/actions/repositoryActions';
import "./../styles/Login.scss";
import ACLoadingPanel from '../components/shared/ACLoadingPanel';
import LoginSlider from '../components/shared/LoginSlider';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest, RAFAuthModule } from '../RAFAzure/AuthModule';
import { Logout, setSessionStorage } from '../RAFComponents/helpers/AppHelper';
import { clearAllCookies, clearLocalSessionStorages } from '../RAFComponents/helpers/utils';
import { RMStorageKey } from '../constants/Common/RMConstants';
import { MenuCategory } from '../constants/MenuConstant/RAFMenuCategory';


const LogoutPage = ({ }) => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const clientLogo = `${window.location.origin}/Content/img/workesio logo.png`;

    //call logoutClick on component load
    useEffect(() => {
        console.log("LogoutPage useEffect");
        logoutClick();
    }, []);



    // const loginSubmit = (event) => {
    //     event.preventDefault();
    // };

    const loginClick = () => {
        instance.loginRedirect(loginRequest);
    };

    const logoutClick = async () => {
        //instance.logoutRedirect();
        try {//try catch added only here to prevent the app from crashing if the logout fails
            await Logout();
            clearLocalSessionStorages();
        } catch (error) {
            setSessionStorage(RMStorageKey.isAdmin, false, "false");
            setSessionStorage(
                RMStorageKey.currentMenuCategory,
                false,
                MenuCategory.Default
            );
            clearAllCookies();
            clearLocalSessionStorages();
            //mslaInstance.logOutAD();
            instance.logoutRedirect();
        }
    };

    return (
        <>


            <div className="h-100">
                <div className="h-100 content panel p-0">
                    <div className="h-100 d-flex justify-content-lg-end justify-content-center">
                        <div className="right-section d-none d-lg-block min-vh-100">
                            <div className="h-100 position-relative">
                                <Suspense fallback={<ACLoadingPanel loadingText="Loading..." />}>
                                    <LoginSlider />
                                </Suspense>
                            </div>
                        </div>
                        <div className="left-section bg-white">
                            <div className="loginContainer overflow-auto d-flex align-items-center flex-column">
                                <div className="w-100 formContainer d-flex flex-column align-items-center justify-content-center">
                                    <div className="text-center">
                                        <img src={clientLogo} alt="Product Logo" className="clientlogoImage mb-3" />
                                        <div className="d-flex justify-content-center align-items-center pb-2">
                                            <h5>Experience Seamless Intelligent Outcomes</h5>
                                        </div>
                                        {isAuthenticated && <ButtonComponent type="button" isPrimary cssClass='w-75' onClick={logoutClick}>Logout</ButtonComponent>}
                                        {!isAuthenticated && (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) &&
                                            // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
                                            <ButtonComponent type="button" isPrimary cssClass='w-75' onClick={loginClick}>Login</ButtonComponent>
                                        }
                                        {/* <form
                                            onSubmit={loginSubmit}
                                            className="d-flex justify-content-center align-items-center flex-column">
                                            <ButtonComponent type="submit" isPrimary cssClass='w-75'>Login</ButtonComponent>
                                        </form> */}
                                        <div id="redirectDiv" className="hidden"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

// LoginPage.propTypes = {
//     redirectStartPage: PropTypes.string,
// };

export default LogoutPage;