import React, { ComponentType, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

const lazyRetry = function (componentImport, name) {
    return new Promise<{ default: ComponentType<any>; }>((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
        );
        // try to import the component
        componentImport().then((component) => {
            window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
        });
    });
};

const ActivatePage = React.lazy(() =>
    lazyRetry(() => import("../pages/ActivatePage"), "ActivatePage")
);

const InvitePage = React.lazy(() =>
    lazyRetry(() => import("../pages/InvitePage"), "InvitePage")
);

const LoginPage = React.lazy(() =>
    lazyRetry(
        () => import("../pages/LoginPage"),
        "LoginPage"
    )
);
const LoginPageNew = React.lazy(() =>
    lazyRetry(
        () => import("../pages/LoginPageNew"),
        "LoginPageNew"
    )
);
const LogoutPage = React.lazy(() =>
    lazyRetry(
        () => import("../pages/LogoutPage"),
        "LogoutPage"
    )
);

const SignoutPage = React.lazy(() =>
    lazyRetry(
        () => import("../pages/SignoutPage"),
        "SignoutPage"
    )
);

export const AppUnAuthenticated = () => {
    let location = useLocation();
    return (
        <Suspense fallback={<div></div>}>
            <Routes
                location={location}
            //key={location.pathname}
            >
                <Route
                    key={"/activate"}
                    path={"/activate"}
                    element={<ActivatePage />}
                />
                <Route
                    key={"/SecureIDSignUp"}
                    path={"/SecureIDSignUp"}
                    element={<ActivatePage loginMethod="SecureIDSignUp" />}
                />
                <Route
                    key={"/MSSignUp"}
                    path={"/MSSignUp"}
                    element={<ActivatePage loginMethod="MSSignUp" />}
                />
                <Route
                    key={"/invite"}
                    path={"/invite"}
                    element={<InvitePage />}
                />
                <Route
                    key={"/logout/:accountType?"}
                    path={"/logout/:accountType?"}
                    element={<LogoutPage />}
                />
                <Route
                    key={"/signout"}
                    path={"/signout"}
                    element={<SignoutPage />}
                />
                <Route
                    key={"/loginnew"}
                    path={"/loginnew"}
                    element={<LoginPageNew />}
                />
                <Route path="*" element={<LoginPage />} />
            </Routes>
        </Suspense>
    );
};